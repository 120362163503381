import React from 'react';
import 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Heading, { StyledHeading } from '~components/Heading';
import { IconCard } from '~components/Card';
import ImageList, { imageListStyle } from '~components/ImageList';
import Text from '~components/Text';

import Access from '~components/Svg/Access';
import Bag from '~components/Svg/Bag';
import Check from '~components/Svg/Check';
import Directions from '~components/Svg/Directions';
import Globe from '~components/Svg/Globe';
import Pointer from '~components/Svg/Pointer';

import { VARIANT } from '../../enums';
const CelebratePage: React.FC = () => (
  <Layout>
    <SEO title="Celebrate Every Person" desc="" pathname="/aspirations/celebrate-every-person" />
    <Hero title="Be a place that honours, values and celebrates the whole of every person">
      <StaticImage src="../../images/edi-image.jpg" alt="" layout="fullWidth" />
    </Hero>
    <div tw="container mb-24">
      <div tw="max-w-5xl">
        <Text>
          We are committed to nurturing an equitable, diverse and inclusive culture and environment
          for everyone who learns and works at Mohawk.
        </Text>
      </div>
    </div>
    <ImageList
      title="Strategic Direction"
      items={[
        'Implement our multi-year Equity, Diversity and Inclusion (EDI) Action Plan and support the outputs and strategies of the EDI Steering Committee.',
        'Assess and measure progress against our EDI Action Plan and our five EDI commitments.',
        'Report on the completed goals, actions and outcomes of the EDI Action Plan and commitments.',
        'Develop and increase student recruitment from new international markets, creating more diversity to enrich and support how all students learn and are supported.',
        'Implement EDI processes throughout the entire employee life-cycle.'
      ]}
    >
      <StaticImage
        src="../../images/edi-report-cover.jpg"
        alt=""
        layout="fullWidth"
        style={imageListStyle}
      />
    </ImageList>
    <div tw="mb-24 bg-orange text-gray-800 py-12">
      <div tw="container">
        <div tw="justify-between space-x-6 items-center md:(flex)">
          <Access tw="h-32 w-32 flex-shrink-0 md:(order-2 h-48 w-48)" />
          <div tw="md:(order-1)">
            <Heading level="h2" tw="mt-0">
              Leadership Outcome
            </Heading>
            <Text tw="text-2xl font-bold">
              We will be recognized as an equity, diversity and inclusion leader for our advancement
              of social justice initiatives focusing on recruitment, participation and advancement
              for all, creating a safe and caring campus for our students, employees and community.
            </Text>
          </div>
        </div>
      </div>
    </div>
    <div tw="container">
      <div tw="max-w-5xl mb-12">
        <Heading level="h2" tw="text-rose">
          Building on Momentum
        </Heading>
        <Text tw="text-xl font-bold">
          By strengthening our leadership in equity, diversity and inclusion, we established clear
          expectations for access, equity and inclusion for all students and for implementing these
          principles in our policies, practices, plans and culture.
        </Text>
        {/* <Heading level="h3">1. Mohawk best</Heading>
        <Text>
          Our first college-wide Equity, Diversity and Inclusion Action Plan identifies specific
          actions for each of the college’s five EDI commitments:
        </Text>
        <ul tw="list-inside list-disc">
          <li>Attract and retain talent reflective of community</li>
          <li>Create an inclusive workplace</li>
          <li>Strengthen inclusive competencies</li>
          <li>Develop and implement equity and inclusion framework</li>
          <li>
            Expand use of Universal Design for Learning/Advance employees’ knowledge and skills on
            creating accessible spaces and documents
          </li>
        </ul>
        <Heading level="h3">2. Mohawk only</Heading>
        <Text>
          Mohawk’s one-of-a-kind Social Inc. provides information on equity and diversity issues and
          raises awareness about systemic discrimination. Its locations on Mohawk’s three campuses
          offer safe, respectful and positive spaces for all students.
        </Text> */}
      </div>
      <StyledHeading>Aspirations</StyledHeading>
      <div tw="grid gap-10 my-12 md:(grid-cols-2) lg:(grid-cols-3)">
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/truth-reconciliation"
          Icon={<Directions tw="w-12" />}
        >
          Meaningfully advance Truth and Reconciliation
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/future-ready" Icon={<Check tw="w-12" />}>
          Enable Future Ready education, research, programs, services and experiences – for
          students, employees and our community
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/education" Icon={<Pointer tw="w-12" />}>
          Ensure access to education and reduce barriers for all
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/workforce-development"
          Icon={<Bag tw="w-12" />}
        >
          Lead in workforce development
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/climate-change" Icon={<Globe tw="w-12" />}>
          Make a measurable impact on climate change
        </IconCard>
      </div>
    </div>
  </Layout>
);

export default CelebratePage;
